<template>
  <div>

    <v-btn outlined color="primary" @click="openRevisionsModal()">Wijzigingsgeschiedenis</v-btn>

    <v-dialog @click:outside="closeRevisionsModal" v-model="showRevisionsModal" max-width="800" scrollable>
      <v-card>
        <v-card-title>
          Wijzigingsgeschiedenis
        </v-card-title>
        <v-card-text>

          <v-skeleton-loader type="table-heading, divider, text@8" v-if="loading"></v-skeleton-loader>

          <p class="text-caption">Versie terugzetten: Het gehele formulier wordt teruggezet, met uitzondering van het formuliernaam en toegewezen gebruikers.</p>

          <v-data-table
              v-if="!loading"
              :headers="headers"
              :items="form_revisions"
              :items-per-page="10"
              :sort-by="['updated_at']"
              :sort-desc="[true]"
              class="rounded-lg"
          >
            <template #item.updated_at="{ item }">
              {{ item.updated_at|formatDate }}
            </template>
            <template #item.restore="{ item, index }">
              <template v-if="index === 0">
                Huidige versie
              </template>
              <v-btn
                  v-if="index > 0"
                  small
                  depressed
                  color="secondary"
                  outlined
                  @click="restoreRevision(item.revision_id)"
                  :loading="revisionRestoring"
              >Terugzetten</v-btn>
            </template>
          </v-data-table>

        </v-card-text>
        <v-card-actions class="background-color">
          <v-btn
              color="secondary"
              outlined
              @click="closeRevisionsModal"
          >
            Sluit
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>
<script>

import axios from "axios";

export default {
  name: 'FormRevisions',
  props: {
    form_id: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Datum',
          value: 'updated_at',
        },
        {
          text: 'Gebruiker',
          value: 'user.username',
        },
        {
          text: 'Terugzetten',
          value: 'restore',
        }
        ],
      form_revisions: [],
      showRevisionsModal: false,
      revisionRestoring: false,
    }
  },
  methods: {
    openRevisionsModal() {
      // Get revisions from API
      this.getFormRevisionsFromApi();
      // Show modal
      this.showRevisionsModal = true;
    },
    closeRevisionsModal() {
      this.showRevisionsModal = false;
    },
    getFormRevisionsFromApi () {
      this.loading = true
      // Fetch results form the API
      axios
          .get('api/form/'+this.form_id+'/revisions')
          .then(response => {
            this.form_revisions = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error)
            // this.errored = true
          })
          .finally(() => this.loading = false)
    },
    restoreRevision(revision_id) {
      this.revisionRestoring = true;

      return axios
          .get('api/form/'+this.form_id+'/revision-restore/' + revision_id)
          .then(() => {
            // Show success message
            this.$toast.success("Revisie succesvol teruggezet. U wordt nu doorverwezen naar de nieuwe versie.");
            // Refresh the page after 3 seconds. Do this as a cheap way to view the latest version of the form with the restored revision.
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          })
          .catch(error => {
            console.log(error)
            this.$toast.error("Kan de revisie niet terugzetten. " + error);
            this.revisionRestoring = false
          })
    }
  },
}
</script>
<style lang="scss">

</style>
